import React, { useMemo } from "react";

import { ArrowRightCircleIcon } from "lucide-react";
import { DateTime } from "luxon";
import Link from "next/link";
import tw from "twin.macro";

import { SalesChart } from "@/app/core/charts/SalesChart";
import { Stats } from "@/app/core/charts/stats/Stats";
import { useApiQuery } from "@/app/core/http/hooks/useApiQuery";
import { routes } from "@/app/core/http/routes";
import { Card } from "@/app/core/ui/components/Card";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { number } from "@/app/core/utils";
import { CommercialTargets } from "@/app/crm/commercial/components/CommercialTargets";
import { DocumentGroupCard } from "@/app/crm/partner/documents/components/DocumentGroupCard";
import type { CommercialDashboard as CommercialDashboardData } from "@obd/common";

interface CommercialDashboardProps {
  dashboard: CommercialDashboardData;
  stretch?: boolean;
  leadsSubtitle?: string;
  showTargets?: boolean;
  showDocuments?: boolean;
  showProfileHint?: boolean;
}

export const CommercialDashboard: React.FC<CommercialDashboardProps> = ({
  dashboard,
  stretch = false,
  showTargets = false,
  showDocuments = false,
  showProfileHint = false,
}) => {
  const salesDiffPercent = useMemo(() => {
    if (dashboard.monthlySales.delta === 0) {
      return dashboard.monthlySales.current;
    }

    return +(
      ((dashboard.monthlySales.current - dashboard.monthlySales.delta) /
        dashboard.monthlySales.delta) *
      100
    ).toFixed(2);
  }, [dashboard.monthlySales]);

  const { data: salesTargets } = useApiQuery(
    ["getCommercialSalesTargets", Number(dashboard.entityId)],
    {
      request: { params: { commercialId: Number(dashboard.entityId) } },
      enabled: Boolean(dashboard.entityId),
    }
  );

  const currentTarget =
    showTargets && salesTargets?.find((t) => t.year === new Date().getFullYear());

  return (
    <Flex tw="flex-col gap-3">
      <Card padding={false} tw="flex-col gap-3">
        <Stats
          items={[
            {
              title: `Facturación ${DateTime.now().setLocale("es-ES").get("monthLong")}`,
              value: number.toCurrency(dashboard.monthlySales.current),
              indicator: {
                diff: `${salesDiffPercent > 0 ? "+" : ""}${salesDiffPercent} %`,
                isPositive: salesDiffPercent > 0,
              },
            },
            {
              title: "Clientes en cartera",
              value: String(dashboard.partnerCount),
            },
            {
              title: "Ofertas pendientes",
              value: number.toCurrency(dashboard.pendingOffers.amount),
              extra: `(${dashboard.pendingOffers.count})`,
            },
            {
              title: "Pedidos abiertos",
              value: number.toCurrency(dashboard.pendingOrders.amount),
              extra: `(${dashboard.pendingOrders.count})`,
            },
          ]}
        />
        <Flex tw="px-3 h-[400px] w-full">
          <SalesChart
            targets={salesTargets}
            sales={dashboard.sales.history}
            years={dashboard.sales.years}
            current={dashboard.monthlySales.current}
          />
        </Flex>
      </Card>

      <Flex tw="gap-1.5 flex-col xl:(flex-row)">
        {showTargets && currentTarget && (
          <Card
            tw="flex-col gap-0.5"
            css={stretch ? {} : tw`xl:(max-h-[50rem] overflow-y-auto)`}
          >
            <Text weight="bolder" size="xl" tw="mb-2">
              Objetivos {new Date().getFullYear()}
            </Text>

            <CommercialTargets
              target={currentTarget}
              monthlySales={dashboard.monthlySales.current}
            />
          </Card>
        )}
      </Flex>

      {showDocuments && (
        <>
          <DocumentGroupCard
            title="Ofertas pendientes"
            showIfEmpty
            documents={{
              count: dashboard.pendingOffers.count,
              queryKey: "getCommercialOffers",
              documentKind: "offer",
              enableOfferClosing: true,
              exportable: true,
              request: {
                params: { commercialId: dashboard.entityId },
                query: {
                  limit: 15,
                  facets: JSON.stringify([
                    [{ id: "status", op: "in", value: ["open"] }],
                  ]) as any,
                },
              },
            }}
          />

          <DocumentGroupCard
            title="Pedidos pendientes"
            showIfEmpty
            documents={{
              count: dashboard.pendingOrders.count,
              queryKey: "getCommercialOrders",
              documentKind: "order",
              exportable: true,
              request: {
                params: { commercialId: dashboard.entityId },
                query: {
                  limit: 15,
                  facets: JSON.stringify([
                    [{ id: "status", op: "in", value: ["open"] }],
                  ]) as any,
                },
              },
            }}
          />
        </>
      )}

      {showProfileHint && (
        <Card width="auto" tw="lg:w-1/2 flex-col">
          <Text weight="medium" color="gray-600">
            Tus documentos y leads pendientes ahora están en la página Perfil.
          </Text>

          <Flex tw="flex-col mt-1.5 text-md gap-0.5">
            {(
              [
                ["Ver documentos", routes.me.index()],
                ["Ver leads pendientes", routes.me.index("leads")],
                ["Ver mis actividades", routes.me.index("actividades")],
              ] as const
            ).map(([title, href]) => (
              <Flex tw="items-center gap-0.75" key={title}>
                <ArrowRightCircleIcon tw="text-gray-600" size="15" />
                <Link tw="text-accent-faded font-bold hover:underline" href={href}>
                  {title}
                </Link>
              </Flex>
            ))}
          </Flex>
        </Card>
      )}
    </Flex>
  );
};
