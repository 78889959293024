import React from "react";

import { useRouter } from "next/router";

import { routes } from "@/app/core/http/routes";
import { Card } from "@/app/core/ui/components/Card";
import { Flex } from "@/app/core/ui/components/Flex";
import { QuantityIndicator } from "@/app/core/ui/components/QuantityIndicator";
import { Text } from "@/app/core/ui/components/Text";
import { ActivityCard } from "@/app/crm/activity/components/ActivityCard";
import type { LeadManagerDashboard as LeadManagerDashboardType } from "@obd/common";

interface LeadManagerDashboardProps {
  dashboard: LeadManagerDashboardType;
}

export const LeadManagerDashboard: React.FC<LeadManagerDashboardProps> = ({
  dashboard,
}) => {
  const { push } = useRouter();

  return (
    <Flex tw="w-full">
      <Card tw="flex-col xl:basis-3/6 gap-2">
        <Flex tw="flex-col gap-0.5">
          <Text weight="bolder" size="xl">
            Leads pendientes
          </Text>
          <Text weight="medium" color="gray-600">
            Actividades que aún no has completado, o de las que esperas una acción
          </Text>
        </Flex>

        <Flex tw="flex-col">
          {(
            [
              [dashboard.pendingActivities.late, true],
              [dashboard.pendingActivities.upcoming, false],
            ] as const
          ).map(([activities, isLate]) => (
            <Flex tw="flex-col w-full" key={`activities-${isLate}`}>
              {!!activities.length && (
                <Flex tw="gap-1 items-center mb-2">
                  <Text size="md" weight="bold" color="gray-600">
                    {isLate ? "Pendiente" : "Próximo"}
                  </Text>
                  <QuantityIndicator
                    tw="bg-gray-200 mt-0.25"
                    size="sm"
                    weight="bolder"
                    color="gray-700"
                  >
                    {activities.length}
                  </QuantityIndicator>
                </Flex>
              )}

              {activities.map((activity, i) => (
                <ActivityCard
                  key={activity.id}
                  activity={activity}
                  isFirst={i === 0}
                  isLast={i === activities.length - 1}
                  isLonely={activities.length === 1}
                  type="timeline"
                  onClick={() => push(routes.leads.id(activity.lead.id))}
                />
              ))}
            </Flex>
          ))}
        </Flex>
      </Card>
    </Flex>
  );
};
