import React from "react";

import { Page } from "@/app/core/layouts/page/Page";
import type { PageComponent } from "@/app/core/types/tsx";
import { Text } from "@/app/core/ui/components/Text";
import { Dashboard } from "@/app/user/dashboard/Dashboard";
import { useUser } from "@/app/user/hooks/useUser";

const HomePage: PageComponent = () => {
  const { data: user } = useUser();

  return (
    <Page>
      <Text size="2xl" weight="bolder" tw="mt-1.5 mb-3">
        Bienvenid@, {user && user.firstName} 👋
      </Text>

      <Dashboard />
    </Page>
  );
};

export default HomePage;
