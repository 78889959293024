import React, { useMemo, useState } from "react";

import { linearGradientDef } from "@nivo/core";
import type { CustomLayer, Serie } from "@nivo/line";
import { ResponsiveLine } from "@nivo/line";
import { formatLocale } from "d3-format";
import { DateTime } from "luxon";
import { theme as twTheme } from "twin.macro";

import { Badge } from "@/app/core/ui/components/Badge";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { number } from "@/app/core/utils";
import type { ICommercialSalesTarget, IPartnerSale } from "@obd/common";
import { capitalize } from "@obd/common";

const CustomLine: (y: number) => CustomLayer =
  (activeYear) =>
  // eslint-disable-next-line react/display-name
  ({ series, lineGenerator, xScale, yScale }) => {
    return (
      <>
        {series.map(({ id, data, color, isTarget }) => (
          <path
            key={id}
            d={lineGenerator(
              data.map((d) => ({
                x: (xScale as any)(d.data.x),
                y: (yScale as any)(d.data.y),
              }))
            )}
            fill="none"
            stroke={color}
            style={
              // eslint-disable-next-line no-nested-ternary
              isTarget
                ? { strokeWidth: 0.3, strokeOpacity: 1 }
                : activeYear === id
                ? {
                    strokeWidth: 1,
                    strokeOpacity: 0.5,
                  }
                : {
                    strokeWidth: 0.6,
                    opacity: 0,
                    strokeDasharray: "3, 3",
                  }
            }
          />
        ))}
      </>
    );
  };

interface SalesChartProps extends React.ComponentProps<typeof Flex> {
  sales: IPartnerSale[];
  years?: Record<number, number>;
  targets?: ICommercialSalesTarget[];
  current?: number;
  theme?: Record<"obj" | "accent1" | "accent2" | "muted", string>;
}

export const SalesChart: React.FC<SalesChartProps> = ({
  sales,
  targets,
  years,
  current,
  theme = { obj: "#FCAB64", accent1: "#456fe8", accent2: "#19b0ec", muted: "#c9d2ec" },
  ...props
}) => {
  const [activeYear, setActiveYear] = useState(new Date().getFullYear());
  const data = useMemo<Serie[]>(() => {
    const currentTarget = targets?.find((t) => t.year === activeYear);

    return [
      ...(currentTarget
        ? [
            {
              id: "Obj.",
              color: theme.obj,
              isTarget: true,
              data: currentTarget.months.map((t) => ({
                x: t.monthShort,
                y: t.amount,
              })),
            },
          ]
        : []),
      ...sales.map((s) => {
        return {
          id: s.year,
          color: s.year === activeYear ? theme.accent1 : theme.muted,
          data: [
            ...s.months
              .filter(
                (e) =>
                  !(
                    s.year === new Date().getFullYear() &&
                    current !== undefined &&
                    e.monthNumber === DateTime.now().month
                  )
              )
              .map((m) => {
                return {
                  x: m.monthShort,
                  y: m.amount,
                };
              }),
            ...(s.year === new Date().getFullYear() && current !== undefined
              ? [
                  {
                    x: capitalize(DateTime.now().setLocale("es-ES").monthShort),
                    y: current,
                  },
                ]
              : []),
          ],
        };
      }),
    ];
  }, [activeYear, current, sales, targets, theme.accent1, theme.muted, theme.obj]);

  return (
    <Flex tw="flex-col w-full h-full" {...props}>
      <ResponsiveLine
        data={data}
        curve="monotoneX"
        animate
        enableSlices="x"
        enableCrosshair
        crosshairType="cross"
        enableArea
        margin={{ top: 30, right: 20, bottom: 50, left: 53 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          max: "auto",
          min: "auto",
        }}
        axisLeft={{
          tickValues: 5,
          tickSize: 5,
          tickPadding: 10,
          format: formatLocale({
            currency: ["", " €"],
            decimal: ",",
            thousands: ".",
          } as any).format("$.2s") as any,
        }}
        yFormat={
          formatLocale({
            currency: ["", " €"],
            decimal: ",",
            thousands: ".",
          } as any).format("$.2s") as any
        }
        pointColor={{ from: "color", modifiers: [["brighter", 0.2]] }}
        pointBorderWidth={0.5}
        pointBorderColor={{
          from: "color",
          modifiers: [["darker", 0.1]],
        }}
        useMesh={true}
        motionConfig="stiff"
        layers={[
          "crosshair",
          "grid",
          "markers",
          "areas",
          CustomLine(activeYear) ?? "lines",
          "slices",
          "points",
          "axes",
          "legends",
          "mesh",
        ]}
        legends={[
          {
            onClick: (d) => (typeof d.id === "number" ? setActiveYear(Number(d.id)) : {}),
            anchor: "top-right",
            direction: "row",
            justify: false,
            translateX: 7,
            translateY: -33,
            itemsSpacing: 1,
            itemDirection: "left-to-right",
            itemWidth: 60,
            itemHeight: 25,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        colors={{ datum: "color" }}
        defs={[
          linearGradientDef(`gradient-${theme.accent1}-${theme.accent2}`, [
            { offset: 40, color: theme.accent1, opacity: 1 },
            { offset: 100, color: theme.accent2, opacity: 1 },
          ]),
          linearGradientDef("noGradient", [
            { offset: 10, color: "inherit", opacity: 0 },
            { offset: 100, color: "inherit", opacity: 0 },
          ]),
        ]}
        fill={[
          {
            match: (d) => d.id === activeYear,
            id: `gradient-${theme.accent1}-${theme.accent2}`,
          },
          {
            match: "*",
            id: "noGradient",
          },
        ]}
        enableGridY={false}
        theme={{
          fontFamily: "inherit",
          tooltip: {
            container: {
              fontSize: 12,
            },
          },
          axis: {
            ticks: {
              line: { stroke: twTheme("colors.gray.300") },
              text: {
                fill: twTheme("colors.gray.600"),
              },
            },
          },
          grid: {
            line: {
              stroke: twTheme("colors.gray.200"),
            },
          },
        }}
      />

      <Badge
        size="sm"
        weight="bold"
        tw="self-end bg-gray-150 border border-gray-200 mb-2 rounded-lg gap-0.5"
        noGap
      >
        <Text as="span" weight="medium" color="gray-800">
          Total {activeYear}:
        </Text>
        {number.toCurrency(years?.[activeYear] ?? 0)}
      </Badge>
    </Flex>
  );
};
