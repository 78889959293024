import React from "react";

import { useApiQuery } from "@/app/core/http/hooks/useApiQuery";
import { CommercialDashboard } from "@/app/user/dashboard/commercial/CommercialDashboard";
import { CommercialDirectorDashboard } from "@/app/user/dashboard/commercial-director/CommercialDirectorDashboard";
import { LeadManagerDashboard } from "@/app/user/dashboard/lead-manager/LeadManagerDashboard";

interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = () => {
  const { data: dashboard } = useApiQuery("getDashboard");

  if (!dashboard) {
    return null;
  }

  if (dashboard.role === "commercial") {
    return <CommercialDashboard dashboard={dashboard} showTargets showProfileHint />;
  }

  if (dashboard.role === "leadManager") {
    return <LeadManagerDashboard dashboard={dashboard} />;
  }

  if (dashboard.role === "commercialDirector" || dashboard.role === "administrator") {
    return <CommercialDirectorDashboard dashboard={dashboard} />;
  }

  return <></>;
};
