import React from "react";

import { DateTime } from "luxon";
import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { number } from "@/app/core/utils";
import type { ICommercialSalesTarget } from "@obd/common";
import { capitalize } from "@obd/common";

const TargetCard = styled(Flex, {
  variants: {
    isCurrentYear: { true: {}, false: {} },
    isCurrentMonth: { true: {}, false: {} },
    isFutureMonth: { true: {}, false: {} },
    hasDiff: { true: {}, false: {} },
    isInTarget: { true: {}, false: {} },
  },
  compoundVariants: [
    {
      isCurrentYear: true,
      isFutureMonth: true,
      css: tw`opacity-60`,
    },
    {
      isCurrentYear: true,
      isCurrentMonth: true,
      hasDiff: true,
      isInTarget: true,
      css: tw`[box-shadow: 0 4px 6px -1px rgba(144, 244, 135, 0.17)]`,
    },
    {
      isCurrentYear: true,
      isCurrentMonth: true,
      hasDiff: true,
      isInTarget: false,
      css: tw`[box-shadow: 0 4px 6px -1px rgba(244, 135, 135, 0.17)]`,
    },
    {
      hasDiff: true,
      isInTarget: true,
      css: tw`border-[#D5F2D3]`,
    },
    {
      hasDiff: true,
      isInTarget: false,
      css: tw`border-red-300`,
    },
  ],
});

interface CommercialTargetsProps extends React.ComponentProps<typeof Flex> {
  target: ICommercialSalesTarget;
  monthlySales?: number;
}

export const CommercialTargets: React.FC<CommercialTargetsProps> = ({
  target,
  monthlySales,
  ...props
}) => {
  return (
    <>
      {target.months.map(({ monthNumber, amount, diff }, i) => {
        let realDiff = diff;

        if (
          new Date().getFullYear() === target.year &&
          DateTime.now().get("month") === monthNumber &&
          monthlySales !== undefined
        ) {
          realDiff = monthlySales - amount;
        }

        return (
          <TargetCard
            key={"commercial-sale" + monthNumber + i}
            tw="justify-between text-md border rounded-lg overflow-hidden flex-shrink-0 min-w-[30rem] xl:min-w-[auto]"
            isCurrentYear={target.year === new Date().getFullYear()}
            isCurrentMonth={DateTime.now().get("month") === monthNumber}
            isFutureMonth={DateTime.now().get("month") < monthNumber}
            hasDiff={realDiff !== undefined}
            isInTarget={realDiff !== undefined && realDiff >= 0}
            {...props}
          >
            <Flex tw="flex-col px-1.5 py-0.75">
              <Text weight="bold" color="gray-800">
                {capitalize(
                  String(
                    DateTime.fromObject({ month: monthNumber })
                      .setLocale("es-ES")
                      .get("monthLong")
                  )
                )}
              </Text>
              <Text weight="bold">
                {realDiff !== undefined && (
                  <>
                    <Text as="span" color={realDiff >= 0 ? "success" : "red-600"}>
                      {number.toCurrency(Math.round(amount + realDiff))}
                    </Text>
                    <Text as="span" color="gray-600" size="sm" weight="bolder">
                      {" "}
                      /{" "}
                    </Text>
                  </>
                )}
                <Text as="span" color="gray-600" size="sm" weight="bold">
                  {number.toCurrency(amount)}
                </Text>
              </Text>
            </Flex>

            <Flex
              center
              tw="border-l px-2 xl:(px-1 w-[17%]) flex-shrink-0 whitespace-nowrap"
              css={{
                ...(realDiff === undefined ? tw`opacity-0` : {}),
                ...(realDiff !== undefined && realDiff < 0
                  ? tw`border-red-300 bg-[#FFF1F1] text-red-600`
                  : {}),
                ...(realDiff !== undefined && realDiff >= 0
                  ? tw`border-[#D5F2D3] bg-[#F9FFF8] text-success`
                  : {}),
              }}
            >
              <Flex tw="ml-0.25 text-sm font-bolder">
                {amount === 0
                  ? "100 %"
                  : `${Math.floor(((amount + (realDiff ?? 0)) / amount) * 100)} %`}
              </Flex>
            </Flex>
          </TargetCard>
        );
      })}
    </>
  );
};
