import React, { useMemo } from "react";

import { DateTime } from "luxon";

import { SalesChart } from "@/app/core/charts/SalesChart";
import { Stats } from "@/app/core/charts/stats/Stats";
import { Card } from "@/app/core/ui/components/Card";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { number } from "@/app/core/utils";
import {
  type CommercialDirectorDashboard as CommercialDirectorDashboardType,
  type ICommercialSalesTarget,
  capitalize,
} from "@obd/common";

// This is temporarily static
const salesTargets: ICommercialSalesTarget[] = [
  {
    year: 2023,

    months: [
      1007848, 1085795, 1290920, 1107675, 1163743, 1281348, 1401688, 813663, 1132290,
      1161008, 1185623, 1043403,
    ].map((amount, monthNumber) => ({
      amount,
      monthNumber: monthNumber + 1,
      monthShort: capitalize(
        DateTime.fromObject({ month: monthNumber + 1 }, { locale: "es-ES" }).monthShort
      ),
    })),
  },
];

interface CommercialDirectorDashboardProps {
  dashboard: Omit<CommercialDirectorDashboardType, "role">;
}

export const CommercialDirectorDashboard: React.FC<CommercialDirectorDashboardProps> = ({
  dashboard,
}) => {
  const salesDiffPercent = useMemo(() => {
    if (dashboard.monthlySales.delta === 0) {
      return dashboard.monthlySales.current;
    }

    return +(
      ((dashboard.monthlySales.current - dashboard.monthlySales.delta) /
        dashboard.monthlySales.delta) *
      100
    ).toFixed(2);
  }, [dashboard.monthlySales]);

  const newPartnersDiffPercent = useMemo(() => {
    if (dashboard.newPartners.delta === 0) {
      return dashboard.newPartners.current;
    }

    return +(
      ((dashboard.newPartners.current - dashboard.newPartners.delta) /
        dashboard.newPartners.delta) *
      100
    ).toFixed(2);
  }, [dashboard.newPartners]);

  const ytdSalesDiffPercent = useMemo(() => {
    if (dashboard.ytdSales.delta === 0) {
      return dashboard.ytdSales.current;
    }

    return +(
      ((dashboard.ytdSales.current - dashboard.ytdSales.delta) /
        dashboard.ytdSales.delta) *
      100
    ).toFixed(2);
  }, [dashboard.ytdSales]);

  return (
    <Flex tw="flex-col">
      <Text
        tw="px-3 py-0.75 bg-gray-150 self-start border-l border-r border-t rounded-t-lg text-[1.2rem]"
        color="gray-800"
        weight="bold"
      >
        CSI Central de Suministros —{" "}
        {capitalize(
          DateTime.now().setLocale("es-ES").get("monthLong") +
            " " +
            new Date().getFullYear()
        )}
      </Text>
      <Card padding={false} tw="flex-col gap-3 rounded-tl-none">
        <Stats
          items={[
            {
              title: "Facturación",
              value: number.toCurrency(dashboard.monthlySales.current),
              indicator: {
                diff: `${salesDiffPercent > 0 ? "+" : ""}${salesDiffPercent} %`,
                isPositive: salesDiffPercent > 0,
              },
            },
            {
              title: "Nuevos clientes",
              value: number.delimited(dashboard.newPartners.current),
              indicator: {
                diff: `${
                  newPartnersDiffPercent > 0 ? "+" : ""
                }${newPartnersDiffPercent} %`,
                isPositive: newPartnersDiffPercent > 0,
              },
            },
            {
              title: `Facturación acumulada`,
              value: number.toCurrency(dashboard.ytdSales.current),
              indicator: {
                diff: `${ytdSalesDiffPercent > 0 ? "+" : ""}${ytdSalesDiffPercent} %`,
                isPositive: ytdSalesDiffPercent > 0,
              },
            },
          ]}
        />
        <Flex tw="px-3 h-[400px] w-full">
          <SalesChart
            targets={salesTargets}
            sales={dashboard.sales.history}
            years={dashboard.sales.years}
            current={dashboard.monthlySales.current}
          />
        </Flex>
      </Card>
    </Flex>
  );
};
